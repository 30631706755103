import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useReducer } from 'react';
import { basePageWrap } from '../BasePage';
import styles from './EventListPage.module.scss';

import moment from 'moment';
import { forceCheck } from 'react-lazyload';
import EventCategories from '../../components/EventCategories/EventCategories';
import EventFilter from '../../components/EventFilter';
import EventSubmission from '../../components/EventSubmission';
import Icon from '../../components/Icon';
import StreamField from '../../components/StreamField';
import i18n from '../../i18n';
import { dataLayerPush } from '../../utils/datalayer';
// import {abortEventRequests, eventsApi} from "../../utils/api";
import EventListBlock from '../../components/EventListBlock';
import EventSelectedCategories from '../../components/EventSelectedCategories';
import Pagination from '../../components/Pagination';
import {
    abortSingularEventsRequests,
    singularEventsApi,
} from '../../utils/api';

function _filterNull(obj) {
    const ret = {};
    for (const key in obj) {
        if (obj[key] !== null) {
            ret[key] = obj[key];
        }
    }

    return ret;
}

function updateGetParams(reqArgs) {
    const nextUrlParams = new URLSearchParams('');
    if (reqArgs.dateFrom !== undefined) {
        if (typeof reqArgs.dateFrom === 'string') {
            nextUrlParams.set('date_from', reqArgs.dateFrom);
        } else {
            nextUrlParams.set(
                'date_from',
                reqArgs.dateFrom.toISOString().substr(0, 10)
            );
        }
    }
    if (reqArgs.dateTo !== undefined) {
        if (typeof reqArgs.dateTo === 'string') {
            nextUrlParams.set('date_to', reqArgs.dateTo);
        } else {
            nextUrlParams.set(
                'date_to',
                reqArgs.dateTo.toISOString().substr(0, 10)
            );
        }
    }

    if (reqArgs.oneTime !== undefined) {
        nextUrlParams.set('one_time', reqArgs.oneTime);
    }

    if (reqArgs.freeEventsOnly !== undefined) {
        nextUrlParams.set('free_events_only', reqArgs.freeEventsOnly);
    }

    if (reqArgs.digitalEventsOnly !== undefined) {
        nextUrlParams.set('digital_events_only', reqArgs.digitalEventsOnly);
    }

    if (reqArgs.categories) {
        reqArgs.categories.forEach((category) => {
            nextUrlParams.append('categories', category);
        });
    }
    if (reqArgs.page !== undefined && reqArgs.page !== 1) {
        nextUrlParams.set('page', reqArgs.page);
    }

    window.history.replaceState({}, '', `?${nextUrlParams.toString()}`);
}

export const EventListPageContext = createContext({});

export const ACTION_TYPES = {
    REQUEST_STARTED: 'REQUEST_STARTED',
    REQUEST_FAILED: 'REQUEST_FAILED',
    CATEGORIES_RECEIVED: 'CATEGORIES_RECEIVED',
    EVENTS_RECEIVED: 'EVENTS_RECEIVED',
    CHANGE_DATES: 'CHANGE_DATES',
    LOAD_MORE: 'LOAD_MORE',
    TOGGLE_ONE_TIME: 'TOGGLE_ONE_TIME',
    TOGGLE_FREE: 'TOGGLE_FREE',
    TOGGLE_DIGITAL: 'TOGGLE_DIGITAL',
    CATEGORY_SELECTED: 'CATEGORY_SELECTED',
    CLEAR_CATEGORIES: 'CLEAR_CATEGORIES',
    SHOW_SEARCH_RESULTS: 'SHOW_SEARCH_RESULTS',
    SET_REQUEST_ARGUMENTS: 'SET_REQUEST_ARGUMENTS',
    CHANGE_PAGE: 'CHANGE_PAGE',
    RESET: 'RESET',
};

const reducer = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_SSR_FALSE:
            return {
                ...state,
                isSsr: false,
            };
        case ACTION_TYPES.RESET:
            return {
                ...state,
                activeCategories: [],
                categories: [],
                selectedCategories: [],
                hasMoreContent: null,
                oneTime: null,
                dateFrom: null,
                dateTo: null,
                page: 1,
                freeEventsOnly: null,
                digitalEventsOnly: null,
            };

        case ACTION_TYPES.REQUEST_STARTED:
            return { ...state, loading: true };

        case ACTION_TYPES.REQUEST_FAILED:
            return { ...state, loading: false, error: true };

        case ACTION_TYPES.CATEGORIES_RECEIVED:
            const { categories } = action;
            return {
                ...state,
                categories,
            };

        case ACTION_TYPES.EVENTS_RECEIVED:
            const { isFirstPage, hasMoreContent, count } = action;
            const events = isFirstPage ? action.events : [...action.events];
            const nextPage = isFirstPage ? 2 : state.nextPage + 1;
            return {
                ...state,
                events,
                hasMoreContent,
                nextPage,
                loading: false,
                error: false,
                showSearchResults: true,
                count,
                pagination: {
                    next: action.next,
                    previous: action.previous,
                    totalPages: action.totalPages,
                    currentPage: action.currentPage,
                },
            };

        case ACTION_TYPES.CHANGE_DATES:
            const { startDate, endDate } = action;
            return {
                ...state,
                dateFrom: startDate,
                dateTo: endDate ? endDate : startDate,
                page: 1,
                events: [],
            };

        case ACTION_TYPES.CHANGE_PAGE:
            return {
                ...state,
                page: action.page,
            };
        // case ACTION_TYPES.LOAD_MORE:
        //     return { ...state, page: state.nextPage };

        // case ACTION_TYPES.TOGGLE_ONE_TIME:
        //     return { ...state, oneTime: !state.oneTime, page: 1, events: [] };

        case ACTION_TYPES.TOGGLE_FREE:
            if (!state.freeEventsOnly) {
                dataLayerPush({
                    event: 'filter',
                    filtercategory: 'Free events',
                });
            }
            return {
                ...state,
                freeEventsOnly: !state.freeEventsOnly,
                page: 1,
            };

        case ACTION_TYPES.TOGGLE_DIGITAL:
            if (!state.digitalEventsOnly) {
                dataLayerPush({
                    event: 'filter',
                    filtercategory: 'Digital events',
                });
            }
            return {
                ...state,
                digitalEventsOnly: !state.digitalEventsOnly,
                page: 1,
            };

        case ACTION_TYPES.CATEGORY_SELECTED:
            const { category, excludeCategory } = action;

            let sCategories = [
                ...state.selectedCategories.filter(
                    (x) => x !== excludeCategory
                ),
            ];

            if (!excludeCategory) {
                sCategories.push(category.slug);
                dataLayerPush({
                    event: 'filter',
                    filtercategory: category?.title,
                });
            }

            return {
                ...state,
                selectedCategories: sCategories,
                page: 1,
            };

        case ACTION_TYPES.CLEAR_CATEGORIES:
            return {
                ...state,
                selectedCategories: [],
                page: 1,
            };

        case ACTION_TYPES.SHOW_SEARCH_RESULTS:
            const { loading, showSearchResults } = action;
            return {
                ...state,
                showSearchResults: showSearchResults,
                loading,
            };

        case ACTION_TYPES.SET_REQUEST_ARGUMENTS:
            const { requestArguments } = action;
            return {
                ...state,
                requestArguments: requestArguments,
            };

        default:
            throw new Error('Unknown action: ' + action.type);
    }
};

const EventListPage = ({
    contentBlocks,
    eventSubmission,
    categories,
    selectedFilters,
    events,
    title,
}) => {
    let search = typeof window !== 'undefined' ? window.location.search : '';
    const urlParams = new URLSearchParams(search);

    const initialState = {
        activeCategories: categories,
        categories: categories,
        selectedCategories: selectedFilters?.categories
            ? selectedFilters.categories
            : urlParams.getAll('categories'),
        hasMoreContent: null,
        oneTime: urlParams.get('one_time'),
        dateFrom: selectedFilters.dateFrom
            ? moment(selectedFilters.dateFrom)
            : urlParams.get('date_from')
            ? new Date(urlParams.get('date_from'))
            : null,
        dateTo: selectedFilters.dateTo
            ? moment(selectedFilters.dateTo)
            : urlParams.get('date_to')
            ? new Date(urlParams.get('date_to'))
            : null,
        page: urlParams.get('page') || 1,
        freeEventsOnly: selectedFilters.freeEventsOnly
            ? true
            : urlParams.get('free_events_only'),
        digitalEventsOnly: selectedFilters.digitalEventsOnly
            ? true
            : urlParams.get('digital_events_only'),
        nextPage: 2,
        loading: false,
        error: false,
        events: events?.items || [],
        showSearchResults:
            !!selectedFilters.categories ||
            !!selectedFilters.dateFrom ||
            !!selectedFilters.dateTo ||
            urlParams.get('categories') ||
            urlParams.get('date_from') ||
            urlParams.get('date_to') ||
            urlParams.get('one_time'),
        requestArguments: null,
        pagination: events.pagination,
        count: events.count || null,
        isSsr:
            !!selectedFilters.categories ||
            !!selectedFilters.freeEventsOnly ||
            !!selectedFilters.digitalEventsOnly ||
            !!selectedFilters.dateFrom ||
            !!selectedFilters.dateTo,
    };

    useEffect(() => {
        dispatch({
            type: ACTION_TYPES.SET_SSR_FALSE,
        });
    }, []);

    const [state, dispatch] = useReducer(reducer, initialState);

    const showClearBtn =
        !!state.selectedCategories.length ||
        !!state.dateFrom ||
        !!state.dateTo ||
        !!state.freeEventsOnly ||
        !!state.digitalEventsOnly;

    const fetchData = () => {
        // dont fetch on first page load
        if (state.isSsr) {
            return;
        }
        // if (!state.showSearchResults) {
        //     return;
        // }

        if (state.loading) {
            abortSingularEventsRequests();
        }

        dispatch({ type: ACTION_TYPES.REQUEST_STARTED });

        /**
         * Request arguments:
         *
         * page?: number;
         * dateFrom?: Date;
         * dateTo?: Date;
         * oneTime?: boolean;
         * categories?: Array<string>;
         */
        const reqArgs = _filterNull({
            page: state.page,
            categories: state.selectedCategories.length
                ? state.selectedCategories
                : null,
            dateFrom: state.dateFrom,
            dateTo: state.dateTo,
            freeEventsOnly: state.freeEventsOnly ? state.freeEventsOnly : null,
            digitalEventsOnly: state.digitalEventsOnly
                ? state.digitalEventsOnly
                : null,
            oneTime: state.oneTime ? state.oneTime : null,
        });

        updateGetParams(reqArgs);

        dispatch({
            type: ACTION_TYPES.SET_REQUEST_ARGUMENTS,
            requestArguments: reqArgs,
        });

        if (JSON.stringify(reqArgs) === JSON.stringify({ page: 1 })) {
            dispatch({
                type: ACTION_TYPES.SHOW_SEARCH_RESULTS,
                showSearchResults: false,
            });
            return;
        }

        singularEventsApi
            .listEvents(reqArgs)
            .then((data) => {
                dispatch({
                    type: ACTION_TYPES.EVENTS_RECEIVED,
                    events: data.results,
                    hasMoreContent: !!data.next,
                    isFirstPage: !data.previous,
                    totalPages: data.totalPages,
                    currentPage: data.currentPage,
                    next: data.next,
                    showSearchResults: true,
                    previous: data.previous,
                    count: data.count,
                    loading: false,
                });
                dispatch({
                    type: ACTION_TYPES.CATEGORIES_RECEIVED,
                    categories: data.meta.categories,
                });
                forceCheck();
            })
            .catch((e) => {
                // Not an error, we do this every time filter changes while querying the api
                if (e.name === 'AbortError') {
                    return;
                }

                dispatch({ type: ACTION_TYPES.REQUEST_FAILED });
                // dispatch({ type: 'requestFailed' });
                console.error(e);
            });
    };

    useEffect(fetchData, [
        state.oneTime,
        state.page,
        state.dateFrom,
        state.selectedCategories,
        state.dateTo,
        state.freeEventsOnly,
        state.digitalEventsOnly,
    ]);

    const contentContainerClasses = classNames(
        styles['EventListPage__Content'],
        {
            [styles['EventListPage__Content--Loading']]: state.loading,
            [styles['EventListPage__Content--HasSearchResult']]:
                state.showSearchResults,
            [styles['EventListPage__Content--Blank']]: !state.showSearchResults,
            [styles['EventListPage__Content--NoHits']]:
                !!state.showSearchResults && state.count === 0,
        }
    );

    return (
        <div className={styles['EventListPage']}>
            <div className={styles['EventListPage__ContentWrap']}>
                <EventListPageContext.Provider value={{ state, dispatch }}>
                    <div
                        className={classNames(
                            styles['EventListPage__Background'],
                            styles['EventListPage__Background--Black']
                        )}>
                        <div className={styles['EventListPage__TopWrapper']}>
                            <h1 className={styles['EventListPage__Hero']}>
                                {title}
                            </h1>
                            <EventFilter />
                        </div>
                        <div className={styles['EventListPage__FiltersWrap']}>
                            <div className={styles['EventListPage__CatWrap']}>
                                <EventCategories categories={categories} />
                            </div>
                            <h2 className="sr-only">
                                {i18n.t('EventListPage.filters')}
                            </h2>
                            <ul className={styles['EventListPage__Filters']}>
                                <li>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            dispatch({
                                                type: ACTION_TYPES.TOGGLE_FREE,
                                            });
                                        }}
                                        aria-pressed={state.freeEventsOnly}
                                        className={classNames(
                                            styles[
                                                'EventListPage__FilterButton'
                                            ],
                                            {
                                                [styles[
                                                    'EventListPage__FilterButton--Active'
                                                ]]: state.freeEventsOnly,
                                            }
                                        )}>
                                        <div
                                            className={
                                                styles[
                                                    'EventListPage__FilterIcon'
                                                ]
                                            }
                                        />
                                        {i18n.t('EventListPage.freeEventsOnly')}
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            dispatch({
                                                type: ACTION_TYPES.TOGGLE_DIGITAL,
                                            });
                                        }}
                                        aria-pressed={state.digitalEventsOnly}
                                        className={classNames(
                                            styles[
                                                'EventListPage__FilterButton'
                                            ],
                                            {
                                                [styles[
                                                    'EventListPage__FilterButton--Active'
                                                ]]: state.digitalEventsOnly,
                                            }
                                        )}>
                                        <div
                                            className={
                                                styles[
                                                    'EventListPage__FilterIcon'
                                                ]
                                            }
                                        />
                                        {i18n.t(
                                            'EventListPage.digitalEventsOnly'
                                        )}
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div
                            className={classNames(
                                styles['EventListPage__Clear'],
                                {
                                    [styles['EventListPage__Clear--NoHits']]:
                                        !!state.showSearchResults &&
                                        state.count === 0,
                                    [styles[
                                        'EventListPage__Clear--ClearBtnShown'
                                    ]]: showClearBtn,
                                }
                            )}>
                            {!!state.selectedCategories.length && (
                                <h2 className="sr-only">
                                    {i18n.t('EventListPage.selectedCategories')}
                                </h2>
                            )}
                            <EventSelectedCategories />
                            {showClearBtn && (
                                <div
                                    className={classNames(
                                        styles[
                                            'EventListPage__ClearButtonWrapper'
                                        ],
                                        {
                                            [styles[
                                                'EventListPage__ClearButtonWrapper--NoHits'
                                            ]]:
                                                !!state.showSearchResults &&
                                                state.count === 0,
                                        }
                                    )}>
                                    <button
                                        className={
                                            styles['EventListPage__ClearButton']
                                        }
                                        onClick={() => {
                                            dispatch({
                                                type: ACTION_TYPES.RESET,
                                            });
                                        }}
                                        aria-label={i18n.t(
                                            'EventListPage.resetCategoriesAria'
                                        )}>
                                        <Icon
                                            type={'trash'}
                                            size={'fill'}
                                            modifiers={[
                                                styles[
                                                    'EventListPage__ClearIcon'
                                                ],
                                            ]}
                                            color={'gray10'}
                                        />
                                        {i18n.t(
                                            'SearchInput.resetCategoriesText'
                                        )}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div id="top-of-search" className={contentContainerClasses}>
                        <div className={styles['EventListPage__Plattan']} />
                        {!!state.showSearchResults && state.count === 0 && (
                            <div
                                className={
                                    styles['EventListPage__NoHitsWrapper']
                                }>
                                <h2
                                    className={
                                        styles['EventListPage__NoHitsTitle']
                                    }>
                                    {i18n.t('EventListPage.noHitsTitle')}
                                </h2>
                                <p
                                    className={
                                        styles['EventListPage__NoHitsText']
                                    }>
                                    {i18n.t('EventListPage.noHitsText')}
                                </p>
                            </div>
                        )}

                        {!!state.showSearchResults && !state.loading && (
                            <>
                                <EventListBlock
                                    title={
                                        !!state.count &&
                                        i18n.t('EventListPage.searchResults', {
                                            amount: state.count,
                                        })
                                    }
                                    isSearchResult={true}
                                    items={state.events}
                                />
                                {!!state.pagination &&
                                    state.pagination.totalPages > 1 && (
                                        <Pagination />
                                    )}
                            </>
                        )}

                        {state.loading && (
                            <EventListBlock
                                title={''}
                                isSearchResult={true}
                                isLoading={true}
                                items={state.events}
                            />
                        )}

                        {!state.loading &&
                            !state.showSearchResults &&
                            contentBlocks &&
                            contentBlocks.length > 0 && (
                                <div
                                    className={
                                        styles['EventListPage__StreamField']
                                    }>
                                    <StreamField
                                        fromEventList={true}
                                        modifiers={['EventListPage']}
                                        items={contentBlocks}
                                    />
                                </div>
                            )}
                    </div>
                </EventListPageContext.Provider>
            </div>

            {!!eventSubmission && eventSubmission.href && (
                <EventSubmission {...eventSubmission} />
            )}
        </div>
    );
};

EventListPage.defaultProps = {
    title: '',
    eventListTitle: '',
};

EventListPage.propTypes = {
    title: PropTypes.string.isRequired,
    contentBlocks: PropTypes.array,
    inTheSpotlight: PropTypes.array,
    inTheSpotlightTitle: PropTypes.string,
    events: PropTypes.object,
    eventLocations: PropTypes.array,
    eventCategories: PropTypes.array,
    eventListTitle: PropTypes.string,
    mostPopular: PropTypes.array,
};

export default basePageWrap(EventListPage);
